import React from 'react';
import List from '../components/List';

//* mocks
import movies from '../testing/mocks/movies.json';
console.log(movies);
movies.forEach((movie) => console.log(movie));

const Home = () => (
  <>
    <div>The home page</div>
    <List movies={movies} />
  </>
);

export default Home;
