import React from 'react';

interface Props {
  title?: string;
  release?: string;
  rating?: number;
}

const ListItem = ({ title, release }: Props) => (
  <div className="list-item">
    <h1>{title}</h1>
    <h2>{release}</h2>
  </div>
);

export default ListItem;
