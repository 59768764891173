import React from 'react';
import ListItem from './ListItem';

interface Props {
  movies: Movie[];
}

interface Movie {
  title: string;
  release_date: string;
  rating: number;
}

const List = ({ movies }: Props) => (
  <ul>
    {movies.map(({ title, release_date, rating }, idx) => (
      <ListItem
        title={title}
        release={release_date}
        rating={rating}
        key={`${title}${release_date}${idx}`}
      />
    ))}
  </ul>
);

export default List;
