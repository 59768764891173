import React, { useEffect } from 'react';

const Login = () => {
  useEffect(() => {
    // fetch('/')
    //   .then((response) => response.json())
    //   .then((data) => console.log(data));
    fetch('/').then((data) => console.log(data));
  });

  return (
    <>
      <div>Login boi </div>
    </>
  );
};

export default Login;
